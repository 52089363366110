<template>
  <div
      class="form-builder__input"
      :class="schema.styleClasses || ''"
  >
    <q-input
        ref="input"
        :standout="!!schema.standout"
        :borderless="schema.borderless || undefined"
        :model-value="formattedDate"
        @update:model-value="handleInput"
        :disable="!!schema.disabled"
        :required="!!schema.required"
        :label="`${schema.label} ${schema.required ? '*' : ''}`"
        :hide-bottom-space="!!schema.hint"
        :dense="!!schema.dense"
        :autocomplete="schema.autocomplete || ''"
        class="q-custom-date q-field--float"
        :class="className"
        type="date"
        @click="openDatePopup"
    >
      <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
              @hide="updateDate"
          >
            <q-date
                v-model="proxyDate"
                first-day-of-week="1"
                mask="YYYY-MM-DD"
            />
          </q-popup-proxy>
        </q-icon>

        <q-icon v-if="schema.value && schema.hasResetBtn" name="close" @click="reset" class="cursor-pointer" />
      </template>
    </q-input>
  </div>
</template>

<script>
import { date } from 'quasar'

export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      proxyDate: ''
    }
  },
  computed: {
    className () {
      return this.schema.value ? '' : 'q-field--no-placeholder'
    },
    formattedDate () {
      const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
      return this.formatDate(value)
    }
  },
  watch: {
    proxyDate () {
      if (this.$refs.qDateProxy) {
        this.$refs.qDateProxy.hide()
      }
    }
  },
  mounted () {
    const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
    this.proxyDate = this.formatDate(value)
  },
  methods: {
    openDatePopup () {
      this.$refs.qDateProxy.show();
    },
    reset () {
      this.handleInput('')
    },
    formatDate (value) {
      if (!value) return ''
      return date.formatDate(new Date(value), 'YYYY-MM-DD')
    },
    updateDate () {
      this.handleInput(this.proxyDate)
      this.$nextTick(() => {
        const value = (typeof this.schema.get === 'function' && this.schema.get(this.schema.value)) || this.schema.value
        const formattedDate = this.formatDate(value)
        this.$refs.input.getNativeElement().value = formattedDate
      })
    },
    handleInput (e) {
      if (this.schema.max) {
        const max = new Date(this.schema.max).getTime()
        const current = new Date(e).getTime()
        if (current > max) {
          return typeof this.schema.onError === 'function' && this.schema.onError({ type: 'max' })
        }
      }
      typeof this.schema.onChange === 'function' && this.schema.onChange(e)
    }
  }
}
</script>

<style>
.q-custom-date input::-webkit-inner-spin-button,
.q-custom-date input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.q-field--no-placeholder input[type="date"]::before {
  content: '';
  width: 100%;
}
</style>
